import { useAuthStore } from "@/store";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TabsPage from "../views/TabsPage.vue";
import Tabs2Page from "../views/Tabs2Page.vue";
import TabsExamplePage from "../views/TabsExamplePage.vue";
import audio from "../views/audioRecord.vue"

const authCheck = (to: any, from: any, next: any) => {
  const store = useAuthStore();
  if (store.isLoggedIn) {
    if (to.name === "login") {
      next({ name: "tab1" });
    } else {
      next();
    }
  } else {
    if (to.name === "login") {
      next();
    } else {
      next({ name: "login" });
    }
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tabs/tab1"
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/PageLogin.vue"),
    beforeEnter: authCheck,
  },
  {
    name: "pageSwitchClub",
    path: "/switchclub",
    component: () => import("@/views/PageSwitchClub.vue"),
    beforeEnter: authCheck,
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/tab1"
      },
      {
        path: "tab1",
        component: () => import("@/views/Tab1Page.vue"),
        beforeEnter: authCheck
      },
      {
        path: "tab2",
        component: () => import("@/views/Tab2Page.vue"),
        beforeEnter: authCheck
      }
    ]
  },
  {
    path: "/example/",
    component: TabsExamplePage,
    children: [
      {
        path: "",
        redirect: "/example/tab1example"
      },
      {
        path: "tab1example",
        component: () => import("@/views/Tab1ExamplePage.vue"),
        beforeEnter: authCheck
      },
      {
        path: "tab2example",
        component: () => import("@/views/Tab2ExamplePage.vue"),
        beforeEnter: authCheck
      },
      {
        path: "audio",
        component: () => import("@/views/audioRecord.vue"),
        beforeEnter: authCheck
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
