import { defineStore } from "pinia";
import {
  fbLogout,
  fbSignIn
} from "./firebase";

export interface User {
  user: boolean;
  profile: any;
  userError: any;
  userSettings: any;
  uploadProgress: number;
}

interface State {
  user: boolean;
  profile: any;
  idtoken: string;
  error: string | '';
  userSettings: any;
  uploadProgress: number;
}


export const useAuthStore = defineStore("authStore", {
  // convert to a function
  state: (): State => ({
    user: false,
    profile: null,
    idtoken: '',
    error: '',
    userSettings: {
      activeClub: {
        badgeUrl: 'https://cdn.leagues.network/media/ygshmvfm/sgf.svg',
      }
    },
    uploadProgress: 0,

  }),
  getters: {
    isLoggedIn: (state) => state.user !== false,
    userError: (state) => state.error,
  },
  actions: {
    /**
     * listens for state changes, ie a user logging in or out
     * and if logging in, loading the associated profile info
     * @returns
     */
    /**
     *
     * @param data
     */
    async logInUser(email: string, password: string) {
      if(!email.includes('@leagues.football')){
        this.error = 'auth/user-no-access';
        return false;
      }
      try {
        const response = await fbSignIn(email, password);
        this.user = true;
        this.error = '';
        return true;
      } catch (e: any) {
        this.user = false;
        this.error = e.code;
        return false;
      }
    },
    /**
     *
     * @param data
     */
    async logoutUser() {
      await fbLogout();
      return true;
    }
  }
});
