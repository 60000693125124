
import {defineComponent} from 'vue';
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet} from '@ionic/vue';
import {ellipse, square, triangle} from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet},
  setup() {
    return {
      ellipse,
      square,
      triangle,
    }
  }
});
