
import {IonApp, IonRouterOutlet, IonMenuButton, IonIcon, getPlatforms} from "@ionic/vue";
import {useRouter} from "vue-router";
import {defineComponent, onMounted} from "vue";
import {useAuthStore} from "@/store";
import axios from "axios";
import { emitter } from  './bus';
import UploadProgress from "@/components/UploadProgress.vue";


export default defineComponent({
  name: "App",
  components: {
    UploadProgress,
    IonApp,
    IonRouterOutlet,
    IonMenuButton,
    IonIcon
  },
  data(){
    return {
      router: useRouter(),
      showUpload: false,
      badgeImage: ''
    }
  },
  computed: {
    userLoggedIn(){
      const store = useAuthStore();
      if(store.user){
        return true;
      }
      return false;
    },
    uploadProgress(){
      const store = useAuthStore();
      return store.uploadProgress;
    },
    badgeUrl(){
      let image = '';
      if(this.badgeImage !== ''){
        image = this.badgeImage;
      }else{
        image = useAuthStore().userSettings.activeClub.badgeUrl;
      }
      return image;
    },
  },
  created() {
    emitter.on('changeBadge', (e) => {
      this.badgeImage = e;
    });
  },
  watch: {
    userLoggedIn: {
      immediate: true,
      handler(val){
        if(val){
          this.getFetchRequests();
        }
      }
    },
    uploadProgress: function(val){
      if(val === 1){
        setTimeout(() => {
          this.showUpload = false;
        }, 800)
      }else{
        this.showUpload = true;
      }
    }
  },
  methods: {
    async getFetchRequests(){
      const store = useAuthStore();
      // need to save uid in userSettings to see if user changed
      const mod = 'modernizr';
      let localStorageAvailable = false;
      try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        localStorageAvailable = true;
      } catch(e) {
        localStorageAvailable = false;
      }
      if(localStorageAvailable){
        const fetchString = localStorage.getItem('userSettings');
        if(fetchString){
          const fetchObject = JSON.parse(fetchString);
          // Wenn die userSettings/Clubdaten im localStorage älter als eine Stunde sind werden sie gelöscht und neu geladen
          const now = new Date();
          if (now.getTime() > fetchObject.expiry || !fetchObject.activeClub) {
            localStorage.removeItem('userSettings')
            await this.fetchClubId();
          }else{
            store.userSettings.activeClub = fetchObject.activeClub;
          }

        }else{
          await this.fetchClubId();
        }
      }else{
        await this.fetchClubId();
      }
    },
    async fetchClubId(){
      const store = useAuthStore();
      interface TimelineRequest{
        data: any;
        status: any;
        statusText: any;
        headers: any;
        config: any;
        activeClub: string;
      }
      const userToken = await store.idtoken;
      const requestURLGet = 'https://cloud-dev.leagues.football/api/profiles/settings/get?key=userSettings';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.defaults.headers.get['Authorization'] = 'Bearer ' + userToken;
      axios.request<TimelineRequest>({
        method: 'get',
        url: requestURLGet
      }).then((response) => {
        this.getClubData(response.data.activeClub)
      });
    },
    async getClubData(clubId: string){
      const store = useAuthStore();
      const userToken = await store.idtoken;
      const requestURLGet = 'https://cloud-dev.leagues.football/api/clubs/getById?id=' + clubId;
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.defaults.headers.get['Authorization'] = 'Bearer ' + userToken;
      axios.request<string>({
        method: 'get',
        url: requestURLGet
      }).then((response) => {
        const now = new Date()
        localStorage.setItem('userSettings', JSON.stringify({
          activeClub: response.data,
          expiry: now.getTime() + 3600000,
        }));
        store.userSettings.activeClub = response.data;
      });
    }
  }
});
